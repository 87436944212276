import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/website/website/components/buttons/LoginButon.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/components/display/Text.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/components/footer/Footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/website/website/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/website/website/components/NotificationBanner/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/layouts/Navigator.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/public/images/downloads/facebook-icon.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/public/images/downloads/linkedin-icon.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/public/images/downloads/small-tali-logo.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/public/images/downloads/twitter-icon.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/website/website/public/images/downloads/youtube-icon.svg");

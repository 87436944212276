'use client';

import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import styles from './NotificationBanner.module.scss';
import usePersistentStorageState from '~/hooks/usePersistantStorage';

const NotificationBanner = () => {
  const [hideBanner, setHideBanner] = usePersistentStorageState(
    'taliIntegrationWithTelusHealthBannerHidden',
    'false',
  );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);
  if (hideBanner === 'true' || isLoading) {
    return <></>;
  }
  return (
    <div className={styles.notificationBanner}>
      <div className={styles.notificationContainer}>
        Sign up for Tali AI’s next Webinar: Personalize Your Notes in Tali AI
        with CEO Mahshid Yassaei on Sep 26, 2024
        <a
          className={styles.bannerLink}
          href="https://zoom.us/webinar/register/5517260028834/WN_OHBTYbZ3S3K1NY8a2BgBSg"
        >
          Register
        </a>
      </div>
      <button
        aria-label="close"
        className={styles.closeBanner}
        onClick={() => setHideBanner('true')}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default NotificationBanner;
